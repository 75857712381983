<template>
  <div>
    <div class="flix-btn-group">
      <a v-if="checked.length != assistents.length" class="flix-btn flix-btn-default flix-btn-selectAll" href="#" @click.prevent="setCheckedAll"><flixIcon id="check" /> {{ $t('message.allCalendar') }}</a>
      <a v-if="checked.length" class="flix-btn flix-btn-default flix-btn-selectAll" href="#" @click.prevent="setUncheckedAll"><flixIcon id="unchecked" /> {{ $t('message.allCalendar') }}</a>
    </div>
    <ul>
      <li v-for="assistent in assistents" :key="assistent.ID">
        <a href="#" class="flix-html-a" @click.prevent="function () { setCheck(assistent.ID) }">
          <flixIcon id="check" v-if="checked.includes(assistent.ID)" />
          <flixIcon id="unchecked" v-else />
          {{ assistent.title }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    check: {
      type: Array,
      default () { return [] }
    },
    callback: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      checked: this.check,
      assistents: this.$store.state.assistents.assistents
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setCheckedAll () {
      this.checked = []
      this.assistents.forEach(function (assistent) {
        this.checked.push(assistent.ID)
      }.bind(this))
      this.callback(this.checked)
    },
    setUncheckedAll () {
      this.checked = []
      this.callback(this.checked)
    },
    setCheck (id) {
      if (this.checked.includes(id)) {
        this.checked.splice(this.checked.indexOf(id), 1)
      } else {
        this.checked.push(id)
      }
      this.callback(this.checked)
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-btn-selectAll
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: center
  ul
    list-style: none
    padding: 0
    display: flex
    flex-direction: column
    li
      a
        display: flex
        flex-direction: row
        align-items: center
        gap: 5px
</style>
